import { RouteRecordRaw } from 'vue-router';
import AppView from './AppView.vue';
import ViewLink from './components/ViewLink.vue';
import { useAuthStore } from '@/common/stores';

const appRouter: RouteRecordRaw = {
  path: '/app',
  component: AppView,
  children: [
    {
      path: '',
      name: 'app',
      component: ViewLink,
    },
  ],
  beforeEnter: (to, from) => {
    const authStore = useAuthStore();
    console.log(authStore.user);
    if (!authStore.isLoggedIn) {
      return { name: 'login' };
    }

    return true;
  },
};

export default appRouter;
