import { RouteRecordRaw } from 'vue-router';
import AuthView from './AuthView.vue';
import AuthLogin from './components/AuthLogin.vue';
import AuthLogout from './components/AuthLogout.vue';
import RequestPasswordRecover from './components/RequestPasswordRecover.vue';
import ValidateAccessCode from './components/ValidateAccessCode.vue';
import ChangePassword from './components/ChangePassword.vue';
import { useAuthStore } from '@/common/stores';
export default {
  path: '/auth',
  component: AuthView,
  meta: { public: true },
  children: [
    { path: '', redirect: { name: 'login' } },
    {
      path: 'login',
      name: 'login',
      component: AuthLogin,
      meta: { title: 'Login' },
    },
    {
      path: 'logout',
      name: 'logout',
      component: AuthLogout,
      meta: { title: 'Saindo...' },
    },
    {
      path: 'recover',
      name: 'recover-password',
      component: RequestPasswordRecover,
      meta: { title: 'Recuperar senha' },
    },
    {
      path: 'first-access',
      name: 'first-access',
      component: RequestPasswordRecover,
      meta: { title: 'Primeiro acesso' },
    },
    {
      path: 'confirm-code',
      name: 'confirm-code',
      component: ValidateAccessCode,
      meta: { title: 'Confirmar código de segurança' },
      beforeEnter: (to, from) => {
        if (!to.query.email || !to.query.token) {
          return from;
        }
        return true;
      },
    },
    {
      path: 'change-password',
      name: 'change-password',
      component: ChangePassword,
      meta: { title: 'Nova senha' },
      beforeEnter: (to, from) => {
        if (!to.query.email || !to.query.token) {
          return from;
        }
        return true;
      },
    },
  ],
  beforeEnter: async (to, from) => {
    const authStore = useAuthStore();

    console.log(authStore.isLoggedIn, to);

    if (authStore.isLoggedIn && !to.meta.public) {
      return { name: 'app' };
    }

    return true;
  },
} satisfies RouteRecordRaw;
