import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAuthApi } from '../axios';

export const useAuthStore = defineStore('auth', () => {
  const authApi = useAuthApi();
  const userRef = ref<any>(null);

  async function login(data: { email: string; password: string }) {
    const { data: user } = await authApi.post('/login', data);
    userRef.value = user;
  }

  async function logout() {
    await authApi.post('/logout', {});
  }

  async function requestRetrievePasswordCode(data?: { email?: string }) {
    const { data: response } = await authApi.post('/password/retrieve', data);
    return response.token as string;
  }

  async function validateRetrievePasswordCode(data: {
    email: string;
    code: string;
    token: string;
  }) {
    const { data: response } = await authApi.post('/password/validate/code', data);
    return response.token as string;
  }

  async function changePassword(data: {
    token: string;
    email: string;
    password: string;
    repeatPassword: string;
  }) {
    await authApi.post('/password/change', data);
  }

  async function validateUser() {
    try {
      const { data: user } = await authApi.get('');
      userRef.value = user;
    } catch (err) {
      userRef.value = null;
    }
  }

  const isLoggedIn = computed(() => !!userRef.value);
  const displayName = computed(() => {
    const user = userRef.value;
    return user?.name;
  });

  return {
    user: userRef,
    displayName,
    isLoggedIn,
    login,
    logout,
    validateUser,
    requestRetrievePasswordCode,
    validateRetrievePasswordCode,
    changePassword,
  };
});
