import { defineStore } from 'pinia';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useDataApi } from '../axios';
import { useRoute, useRouter } from 'vue-router';

type CompanyType = { name: string; id: number; link?: string };

export const useCompaniesStore = defineStore('companies', () => {
  const isLoadingCompanies = ref(false);
  const isLoadingBranches = ref(false);
  const isLoading = computed(() => isLoadingCompanies.value || isLoadingBranches.value);

  const router = useRouter();
  const route = useRoute();
  const companiesApi = useDataApi('companies');

  const { company: companyQuery, branch: branchQuery } = route.query;

  const companyId = ref<number | string | null>(companyQuery ? (companyQuery as string) : null);
  const branchId = ref<number | string | null>(branchQuery ? (branchQuery as string) : null);

  const companies = ref<CompanyType[]>([]);
  const branches = ref<CompanyType[]>([]);

  const company = computed(() =>
    companies.value.find(({ id }) => parseInt(companyId.value?.toString() || '') === id),
  );
  const branch = computed(() =>
    branches.value.find(({ id }) => parseInt(branchId.value?.toString() || '') === id),
  );

  async function loadCompanies() {
    isLoadingCompanies.value = true;
    try {
      const { data } = await companiesApi.get('');
      companies.value = data || [];
    } catch (error) {
      console.error(error);
    } finally {
      isLoadingCompanies.value = false;
    }
  }

  async function loadBranches(companyId: number | string) {
    isLoadingBranches.value = true;
    try {
      const { data } = await companiesApi.get(`${companyId}`);
      branches.value = data || [];
    } catch (error) {
      console.error(error);
    } finally {
      isLoadingBranches.value = false;
    }
  }

  async function setQuery(companyId: number | string | null, branchId: number | string | null) {
    router.push({
      path: router.currentRoute.value.path,
      query: {
        ...router.currentRoute.value.query,
        company: companyId || undefined,
        branch: branchId || undefined,
      },
    });
  }

  onMounted(() => {
    loadCompanies();
  });

  onUnmounted(() => {
    companyId.value = null;
    branchId.value = null;
    branches.value = [];
    companies.value = [];
  });

  if (companyId.value && branchId.value) {
    loadBranches(companyId.value);
  }

  watch(companyId, (companyId) => {
    if (companyId) {
      branchId.value = null;
      loadBranches(companyId);
    } else {
      branches.value = [];
    }
  });

  watch([companyId, branchId], ([companyId, branchId]) => {
    console.log(companyId, branchId);
    setQuery(companyId, branchId);
  });

  return {
    companyId,
    branchId,
    companies,
    branches,
    company,
    branch,
    isLoading,
  };
});
