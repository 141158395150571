import axios, { AxiosInstance } from 'axios';
import router from '@/router';

console.log({ VUE_APP_API_URL: process.env.VUE_APP_API_URL });

const clients: Map<string, AxiosInstance> = new Map();
const baseApiAxios = axios.create({ baseURL: process.env.VUE_APP_API_URL });

function interceptErrors(axios: AxiosInstance) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (error.code === 'ERR_NETWORK') {
        throw error;
      }

      if (error.response?.status === 401) {
        const currentRoute = router.currentRoute.value;
        if (currentRoute.fullPath !== '/' && !currentRoute.meta.public) {
          router.go(0);
        }
      }
      throw error;
    },
  );
}

export default function useAxiosApiClient(url: string): AxiosInstance {
  if (!clients.has(url)) {
    const client = axios.create({
      baseURL: baseApiAxios.getUri({ url }),
      withCredentials: true,
    });
    interceptErrors(client);
    clients.set(url, client);
  }

  return clients.get(url) as AxiosInstance;
}

export function useAxiosClient(baseURL: string): AxiosInstance {
  if (!clients.has(baseURL)) {
    const client = axios.create({
      baseURL,
    });
    clients.set(baseURL, client);
  }
  return clients.get(baseURL) as AxiosInstance;
}

export function useCoreApi(): AxiosInstance {
  return useAxiosApiClient('/api/v1');
}

export function useAuthApi(): AxiosInstance {
  return useAxiosApiClient('/api/v1/auth');
}

export function useDataApi(entity: string): AxiosInstance {
  return useAxiosApiClient(`/api/v1/data/${entity}`);
}

export function useViaCepApi(): AxiosInstance {
  return useAxiosClient('https://viacep.com.br/ws/');
}
