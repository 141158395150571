<template>
  <div>
    <RouterView v-slot="{ Component, route }">
      <Transition :name="'fade'" appear mode="out-in">
        <component :is="Component" :key="route.path"></component>
      </Transition>
    </RouterView>
  </div>
</template>
<style lang="scss" scoped>
div {
  display: flex;
  justify-content: center;
}
</style>
