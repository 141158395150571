<template>
  <RouterView />
</template>

<script lang="ts" setup></script>

<style lang="scss">
html {
  font-family: 'Gotham', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
:root {
  --default-background: rgb(242 242 242);
  --border-color: rgb(215 215 215);
  --primary-color: rgb(47 117 182);
  --input-height: 28px;
  --border-radius: 5px;
  --header-height: 150px;
  --header-offset: 168px;
}

body {
  position: relative;
  #app {
    button {
      cursor: pointer;
    }

    button[type='submit'] {
      height: var(--input-height);
      font-size: 0.8em;
      color: white;
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-radius: var(--border-radius);
      padding: 0 2.5em;
    }

    div[data-field] {
      display: flex;
      label {
        font-weight: 600;
        font-size: 0.8em;
      }
      input,
      select {
        border: 1px solid var(--border-color);
        background-color: var(--default-background);
        border-radius: var(--border-radius);
        height: var(--input-height);
        font-size: 0.8em;
        padding: 0.2em;
      }

      &[data-field='true'],
      &[data-field='v'] {
        flex-flow: column;
        margin-bottom: 0.6em;
        label {
          margin-bottom: 0.4em;
        }
      }

      &[data-field='h'] {
        flex-flow: row;
        align-items: center;
        margin-right: 2.5em;
        label {
          margin-right: 1em;
        }
      }
    }

    a.button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #883fff;
      border-radius: 8px;
      padding: 0px 24px;
      height: 56px;
      text-decoration: none;
      color: white;
      font-weight: 700;
      font-size: 14px;
      line-height: 48px;
      white-space: nowrap;

      &.primary {
        background: #883fff;
      }

      &.accent {
        background: #f85a2a;
      }
    }
  }
  &:has([data-overlay]),
  &:has(dialog[open]) {
    overflow: hidden;
  }

  form[data-auth-form] {
    padding: 0.8em 1.2em;
    div[data-actions] {
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 768px) {
  body {
    #app {
      a.button {
        font-size: 12px;
      }
    }
  }
}
</style>
