import { RouteRecordRaw } from 'vue-router';
import LandingView from './LandingView.vue';
import LandingContent from './components/LandingContent.vue';

export default {
  path: '/',
  component: LandingView,
  meta: { public: true },
  children: [{ path: '', name: 'landing', component: LandingContent }],
} satisfies RouteRecordRaw;
