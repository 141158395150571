<template>
  <RouterLink :to="{ name: 'landing' }">
    <img />
  </RouterLink>
</template>

<style lang="scss" scoped>
a {
  height: 100%;
  img {
    content: url('~@/assets/logo.png');
  }
}

@media (max-width: 768px) {
  a {
    img {
      content: url('~@/assets/logo-mobile.png');
      height: 50px;
      width: auto;
    }
  }
}

@media (max-width: 467px) {
  a {
    display: none;
    img {
      display: none;
    }
  }
}
</style>
