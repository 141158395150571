import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import authRouter from './modules/auth/router';
import appRouter from './modules/app/router';
import landingRouter from './modules/landing/router';
import { useAuthStore } from './common/stores';
const routes: Array<RouteRecordRaw> = [landingRouter, authRouter, appRouter];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const headerOffset = getComputedStyle(document.documentElement).getPropertyValue(
        '--header-offset',
      );
      console.log({
        headerOffset,
        path: to.path,
        savedPosition,
        value: parseInt(headerOffset, 10),
      });

      const el = document.querySelector(to.hash);
      const top = el ? parseInt(getComputedStyle(el).paddingTop) : 0;

      console.log({
        el,
        top,
        el1: to.hash,
        top1: to.path === '/' ? parseInt(headerOffset, 10) + top : 0,
        behavior: 'smooth',
      });

      return {
        el: to.hash,
        // top: to.path === '/' ? parseInt(headerOffset, 10) + top * 2 : 0,
        behavior: 'smooth',
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  console.log(to.meta);
  if (to.meta.public) {
    return next();
  }
  const authStore = useAuthStore();
  await authStore.validateUser();
  next();
});

export default router;
